import '@lls/lls-kit/clientImports'
import '@lls/lls-kit/clientPrimaryImports'
import '@lls/superkit/clientImports'
import '@lls/viewer/clientImports'
import '@lls/vivi/clientImports'
import '@lls/cocore/clientImports'
import '@lls/front/clientImports'

import { config } from '@lls/front/staticImports'
import configureStore from 'front/shared/store/index'
import { redirect2016ProgramUrls } from 'front/utils/route'
import { createBrowserHistory } from 'history'
import _ from 'lodash/fp.js'
import Pino from 'pino/browser'
import { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDomClient from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

// delay evaluation for esbuild since does not respect order when chunks so we can polyfill SC...
let App
const loadApp = () =>
  import('@lls/front/app').then(m => {
    App = m.default || m
  })
const logger = new Pino({ browser: { asObject: true } })

redirect2016ProgramUrls()

const Root = ({ store }) => {
  useEffect(() => {
    const preloadedState = document.getElementById('id-initial-state')
    preloadedState?.parentNode.removeChild(preloadedState)
    const build = {
      node_env: process.env.NODE_ENV,
      config: process.env.CONF_ENV,
      viewerVersion: config.SSR_viewer
    }
    logger.info(build)
  }, [])

  return (
    <Router history={createBrowserHistory()}>
      <Provider store={store}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Provider>
    </Router>
  )
}
const domReady = () =>
  new Promise(resolve => {
    if (_.includes(document.readyState, ['interactive', 'complete'])) {
      resolve()
    } else {
      window.addEventListener('DOMContentLoaded', resolve)
    }
  })
Promise.all([domReady(), loadApp()]).then(async () => {
  if (globalThis.WEBFLOW_NAVBAR === '1') {
    // we are a webflow page. We preloaded this file (and its chunks) to fasten navigation
    // but we don't need to render anything
    return
  }
  const store = await configureStore({
    initialState: window.__PRELOADED_STATE__ || {}
  })
  window.__PRELOADED_STATE__ = undefined
  ReactDomClient.hydrateRoot(document.getElementById('app'), <Root store={store} />)
})
