import _ from 'lodash/fp.js'

const basename = filePath => filePath.replace(/.*\//, '')

export const isOldEpis = url => {
  return url.startsWith('/files/epis/')
    ? `https://lls-media-public.s3-eu-west-1.amazonaws.com/epi/${basename(url)}`
    : false
}

export const redirect2016ProgramUrls = () => {
  const oldUrl = new URL(window.location.href)
  if (_.flow(_.get('hash'), _.includes('#!programme-2016'))(oldUrl)) {
    window.location.href = _.replace('#!programme-2016/', '', window.location.href)
  }
}
